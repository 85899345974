export const colors = {
  BK_THEME_COLOR: '#11112c',
  HEADER_BK: '#292941',
  BK_COLOR_VVLT: '#34344B',
  BK_COLOR_VLT: '#272731',
  BK_COLOR_LT: '#14141F',
  BK_COLOR_DK: '#171B26',
  BK_COLOR_VD: '#05050E',
  TEXT_WHITE: '#FFFFFF',
  TEXT_GREY: '#737780',
  BRAND_ACCENT: '#F1681B',
  FB_BLUE: '#3b5998'
};

export const defaultStyles = {
  labelStack: {
    width: '40%'
  },
  inputGeneral: {
    marginLeft: 0,
    color: colors.TEXT_WHITE,
  },
  inputStack: {
    width: '60%'
  },
  inputHStack: {
    marginBottom: 30,
    paddingTop: 10
  },
  container: {
    flex: 1,
    backgroundColor: colors.BK_COLOR_DK,
    justifyContent: 'center',
  },
  button: {

    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10


  },
  cards: {
    padding: 10,
  },
  h4: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: 28,
    color: colors.TEXT_WHITE,
    margin: 10,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  },
  labelText: {
    fontWeight: '300',
    fontSize: 14,
    lineHeight: 20,
    color: colors.TEXT_WHITE
  },
  p: {
    fontWeight: '300',
    fontSize: 14,
    lineHeight: 20,
    color: colors.TEXT_WHITE,
    margin: 10,
  }
}