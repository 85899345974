import axios from '../../configurations/request';
import { configService } from '../../services/Config';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Pressable } from 'react-native';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Layout } from '../components/Layout';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, Container, FormControl, Icon, Image, Input, Link, Spacer, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { UserService, userService } from '../../services/User';
import { MaterialIcon } from '../components/Icon';
import { AuthContext } from '../context/AuthContext';




const postUserToBackend = async (userID: string, creds: string, navigation: any) => {
  console.log(userID, creds)
  const baseUrl = configService.apiRoot;
  await axios({
    method: "PUT",
    url: `${baseUrl}users/${userID}`,
    data: {
      access_token: creds,
    }
  });

  navigation.navigate('Pages', { userID })
};



/* FB login flow...
 * A. FB login status if connected -> SKIP TO C
 * B. FB login
 * C. Send userID & accessToken to fb login endpoint
 * D. Receive token
*/

export const LoginScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const context = useContext(AuthContext);
  const [emailVal, setEmailVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');

  const emailLogin = async (email: string, password: string, n?: any) => {
    try {
      await userService.emailLogin(email, password);
      context.setTokenTime(Date.now());
    } catch(e) {
      toast.show({description: "Please check your credentials or create an account"})
    }
  }

  const fbLogin = async () => {
    let retries = 0;
    window.FB.getLoginStatus(async (response) => {
      if (response.status === 'connected') {
        console.log('connected');
        const { userID, accessToken } = response.authResponse;
        try {
          await userService.fbLogin(userID, accessToken);
        } catch(e) {
          if (retries < 1) {
            retries++;
            window.FB.logout();
            fbLogin();
          }
        }
        context.setTokenTime(Date.now());
      } else {
        window.FB.login(async (response) => {
          if (response.status === 'connected') {
            const { userID, accessToken } = response.authResponse;
            await userService.fbLogin(userID, accessToken);
            context.setTokenTime(Date.now());
          }
        }, { config_id: '1393053344769263', auth_type: '', scope: 'pages_show_list,public_profile,email' });
      }
    });
  }

  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={170}>


          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='3xl' style={styles.heading}>
            Sign in to your account
          </Text>
        </Square>

        <Square w={360}>

          <FormControl isRequired>
            <Stack mx="4">
              <FormControl.Label><Text style={styles.formLabel}>Email</Text></FormControl.Label>
              <Input type="text" w="full"
              color={colors.TEXT_WHITE}
              value={emailVal} onChangeText={setEmailVal}
              placeholder="email" />
              <FormControl.HelperText>
                Sign in using your email
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please use a valid email
              </FormControl.ErrorMessage>

              <FormControl.Label><Text style={styles.formLabel}>Password</Text></FormControl.Label>
              <Input type="password" onChangeText={setPasswordVal} color={colors.TEXT_WHITE} placeholder="password" />
              <FormControl.HelperText>
                Enter your password
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                At least 6 characters are required.
              </FormControl.ErrorMessage>
            </Stack>
          </FormControl>
          
          <Box w="full" p="16px">
            <Button w="full" onPress={() => emailLogin(emailVal, passwordVal, navigation)}>Sign In</Button>
          </Box>
        </Square>
        <Square w={360}>
          <Box w="full" p="16px">
            {/*<Button
              leftIcon={<MaterialIcon name="facebook" size="large" color="#fff" />}
              backgroundColor={colors.FB_BLUE}
              w="full" onPress={() => fbLogin(navigation)}>
              Login With Facebook
          </Button>*/}
          </Box>
          <Text color={colors.TEXT_WHITE}>
            Don't have an account? &nbsp;
            <Link color='primary.600' href='sign_up'>
              Sign up
            </Link>
          </Text>
          <Text color={colors.TEXT_WHITE}>
            Forgot password? &nbsp;
            <Link color='primary.600' href='start_reset'>
              Reset Password
            </Link>
          </Text>
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 20,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});