
import { View, Text, Pressable, FlatList, Spacer, Center, VStack, Button, useToast, Input, FormControl, Box, Checkbox, Radio, TextArea, ScrollView, HStack, TextField, Spinner } from "native-base";
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { FlatListProps, ListRenderItem, StyleSheet, TouchableHighlight } from 'react-native';
import { PageService } from '../../services/Page';
import { userService } from "../../services/User";
import { colors, defaultStyles } from "../../styles/theme.style";
import { MaterialIcon } from "../components/Icon";
import { Layout } from "../components/Layout";
import { MediaCard } from "../components/MediaCard";
import { AuthContext, AuthState } from "../context/AuthContext";
import { Message, cheetahService } from "../../services/Chetah";
import { getResponse } from "../../services/WaitingResponses";


export const IdeaExchangeScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [messages, setMessages] = useState<Message[]>([]);
  const [userPrompt, setUserPrompt] = useState('');
  const [settingsId, setSettingsId] = useState('');
  const [conversationId, setConversationId] = useState('');
  const [loading, setLoading] = useState<Boolean>(false);
  
  const loadConversationId = () => {
    setConversationId(route?.params?.conversationId);
  };

  const loadSettingsId = () => {
    if (user?._id) {
      setSettingsId(user._id);
    }
  }

  const submitPrompt = async () => {
    setLoading(true);
    const newConversation = !conversationId;

    // 
    let conversationObj;
    if (newConversation) {
      conversationObj = await cheetahService.createConversation(settingsId, userPrompt);

      navigation.setParams({
        conversationId: conversationObj._id,
      });
    } else {
      conversationObj = await cheetahService.addToConversation(settingsId, conversationId, userPrompt);
    }

    setMessages(conversationObj.messages);
    setLoading(false);
  }

  const renderConversation: ListRenderItem<any> = ({item, index}: {item: Message, index: number}) => (
    <VStack width={'full'}>
      {/** todo replace with an icon of cheetah / person */}
    <HStack style={index % 2 !== 0 ? styles.oddBox : styles.evenBox}>
      {index % 2 !== 0 ? <Text style={styles.p}>AI:</Text>:<Text style={styles.p}>YOU:</Text>}
      <Text style={styles.p}>{item?.content}</Text>
    </HStack>
    </VStack>
  );

  useEffect(loadConversationId, [route?.params?.conversationId]);
  useEffect(loadSettingsId, [user]);

  return (
    <Layout>
      <View style={styles.container}>
        <VStack mx='4'>
          <Text style={styles.h4}>Chat GPT Terminal - GPT 4 coming soon. </Text>
        </VStack>
        <ScrollView flex="1" mx="10" padding={10}>
          <VStack >
            <FlatList flex={"1"} height={"full"} data={messages} renderItem={renderConversation} />
            {loading && <Spinner></Spinner>}
            <VStack style={styles.labelStack} >
              <Text style={styles.labelText} marginTop={5}>Send a message:</Text>
              <Input style={styles.inputGeneral} value={userPrompt} onChangeText={setUserPrompt} onSubmitEditing={submitPrompt}/>
            </VStack>
          </VStack>
        </ScrollView>


      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles,
    {
      iframe: {
        border: 'none',
        backgroundColor: 'white'
      },
      stepBox: {
        backgroundColor: colors.BK_COLOR_VVLT
      },
      oddBox: {
        backgroundColor: colors.BK_COLOR_VVLT,
      },
      evenBox: {},
      labelStack: {
      }
    })
);
