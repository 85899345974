import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useEffect, useState } from "react";

// Create a context
const AuthContext = createContext();

export interface AuthState {
  isLoggedIn?: boolean;
  jwt?: string;
}

export interface AuthState {
  authenticated: boolean,
  user?: {userID: number, _id: string},
}

const AuthProvider = ({ children }) => {
  const logout = () => {
    AsyncStorage.setItem('jwt', '');
    setAuthState({authenticated: false});
  };
  const loadToken = () => {
    console.log('load token...')
    AsyncStorage.getItem('jwt').then((token) => {
      console.log('token', token)
      if (!token) {
        return setAuthState({authenticated: false});
      }
      const pay = token.split('.')[1];
      const user = JSON.parse(atob(pay));
      user.authenticated = user.exp > Date.now()/1000;
      setAuthState(user)
    })
  }
  console.log('auth provider')
  const defaultState: AuthState = {authenticated: true};
  const [authState, setAuthState] = useState(defaultState);
  let [tokenTime, setTokenTime] = useState(''); // Dumb value to trigger render
  setTokenTime = loadToken
  useEffect(() => {
    loadToken()
  }, []);

  return (
    <AuthContext.Provider value={ {authState, setAuthState, setTokenTime, logout} }>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };