
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import { ListRenderItem, StyleSheet } from 'react-native';
import { colors, defaultStyles } from "../../styles/theme.style";

import { Layout } from "../components/Layout";
import { cheetahService } from "../../services/Chetah";
import { AuthContext, AuthState } from "../context/AuthContext";
import { Button, FlatList, Pressable, ScrollView, Text, VStack, View } from "native-base";
import SunEditor from "suneditor-react";

export const HomeScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [showing, setShowing] = useState('');
  const [blogItems, setBlogItems] = useState([]);
  const [limit, setLimit] = useState(5);
  const [offset, setOffset] = useState(0);
  const toggleShowing = (_id: string) => {
    showing === _id ? setShowing(''): setShowing(_id)
  }
  const show = (_id: string) => {
    return showing === _id;
  }

  const fetchPosts = async (settingsId: string) => {
    const posts = await cheetahService.getItems(settingsId, limit, offset);
    return posts;
  }

  const loadMore = async () => {
    setLimit(limit + 5);
  }

  useEffect(() => {
    const settingsId = user?._id as string;
    if (!settingsId) { return; }

    (async() => {
      const posts = await fetchPosts(settingsId);
      setBlogItems(posts);
      console.log(posts, blogItems)
    })()
  }, [user?._id, limit]);

  const renderBlogItems: ListRenderItem<any> = ({ item: post }) => (
    <VStack height={'full'} padding={3}>
    <ScrollView  w="full" height={"32"} marginBottom={0} style={styles.stepBox}>
      <SunEditor height={'full'} disable={true} hideToolbar={true} autoFocus={false} setContents={post.messageContent} />
    </ScrollView>
    <Button onPress={() => navigation.navigate('ArticleTools', {blogId: post._id})}>View In Editor</Button>
    </VStack>
  );

  return (
    <Layout>
      <View style={styles.container}>
        <ScrollView mx='4' maxHeight={"full"}>
          <Text style={styles.p}>Your Posts:</Text>
          <FlatList flex={"1"} height={"full"} data={blogItems} renderItem={renderBlogItems} />
          <Button onPress={() => loadMore()}>Load More...</Button>
        </ScrollView>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles,
    {
      stepBox: {
        backgroundColor: colors.BK_COLOR_VVLT
      }
    })
);