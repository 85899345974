
import { View, Text, Pressable, FlatList, Spacer, Center, VStack, Button, useToast, Input, FormControl, Box, Checkbox, Radio, TextArea, ScrollView, Spinner, Select, HStack } from "native-base";
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { StyleSheet, TouchableHighlight } from 'react-native';
import { PageService } from '../../services/Page';
import { userService } from "../../services/User";
import { colors, defaultStyles } from "../../styles/theme.style";
import { MaterialIcon } from "../components/Icon";
import { Layout } from "../components/Layout";
import { MediaCard } from "../components/MediaCard";
import { AuthContext, AuthState } from "../context/AuthContext";
import { cheetahService } from "../../services/Chetah";
import { getResponse } from "../../services/WaitingResponses";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File


let blogLoaded = false;

export const ArticleDetailsHelperScreen: FunctionComponent<any> = ({ route, navigation }) => {


  const toast = useToast();
  const defaultWaitingText = 'Your text is being generated. And we are finding and inserting photos. This could take a few minutes. Your patience will be rewarded with a nicely written article complete with images.';
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [loading, setLoading] = useState(true);
  const [loadingResult, setLoadingResult] = useState(false);
  const [selectedAction, setSelectedAction] = useState('');
  const [blog, setBlog] = useState('');
  const [editInstructions, setEditInstructions] = useState('');
  const [settingsId, setSettingsId] = useState(user?._id || '');
  const [selectedText, setSelectedText] = useState('');
  const [results, setResults] = useState('');
  const [waitingText, setWaitingText] = useState(defaultWaitingText)
  const [infoOne, setInfoOne] = useState('');

  const loadSettingsId = async () => {
    if (user?._id) {
      setSettingsId(user._id);
    }
  }
  const delay = (t: number) => new Promise(resolve => setTimeout(resolve, t));
  const load = async () => {
    const { params } = route;
      if (params && params.blogId && settingsId) {
        const {blogId} = params;
        setLoading(true);
        setBlog(defaultWaitingText);
        
        const TIMEOUT_MS = 3*60*1000;
        let timedOut = 0;
        setTimeout(() => {++timedOut}, TIMEOUT_MS);

        let finished = 0;
        while(!finished && !timedOut) {
          const resObj = await cheetahService.fetchItem(settingsId, blogId);
          setBlog(resObj.messageContent);
          
          if (resObj.finished) {
            finished = 1;
            setLoading(false);
          }
          await delay(200);
        }
        
      } else {
        setInfoOne(' - Copy and past or select from dashboard');
      }
  }

  const submitAction = async () => {
    setLoadingResult(true);
    
    const actionOptions = {
      editInstructions
    }
    console.log('sub', settingsId, blog, selectedAction, actionOptions)
    const results = await cheetahService.textActions(settingsId, blog, selectedAction, actionOptions);
    setResults(results);
    setLoadingResult(false);

  }

  useEffect(() => {
    load();
  }, [route.params, settingsId]);

  useEffect(() => {
    loadSettingsId();
  }, [user]);

  return (
    <Layout>
      <View style={styles.container}>
        <VStack mx='4'>
          <Text style={styles.h4}>AI Word Cheetah - Tooling for authors</Text>
        </VStack>
        <ScrollView flex="1" mx="4" padding={0}>
          <VStack>


            <VStack p={0} marginTop={5} minHeight={'5'} style={styles.stepBox}>
              <Text p={5} style={styles.labelText}>Here you can use AI to perform edits and other actions on text{infoOne}</Text>

              {loading ?
                <HStack><Spinner m={5} size="lg" /><Text p={5} style={styles.labelText}>{waitingText}</Text></HStack>
                :<></>}
                <>
                  <SunEditor height={'500'} disable={loading} autoFocus={false} setContents={blog} onChange={setBlog} />
                  <Box m={5} maxW="600">
                    <Select style={styles.inputGeneral} selectedValue={selectedAction} minWidth="200" accessibilityLabel="Choose an action"
                      placeholder="Select an action" color={'white'} mt={1} onValueChange={actionValue => setSelectedAction(actionValue)}>
                      <Select.Item label="General Edit - You will indicate desired change" value="editGeneral" />
                      <Select.Item label="Twitter Summary" disabled={true} value="sumTwitter" />
                      <Select.Item label="General Summary" disabled={true} value="sumFacebook" />
                      
                      <Select.Item label="Hashtag & keyword generator" disabled={true} value="hashtags" />
                    </Select>
                    {selectedAction === 'editGeneral' ?
                      <VStack marginTop={5} >
                        <Text style={styles.labelText}>Describe the changes you would like to make.</Text>
                        <Input style={styles.inputGeneral} w="100%" value={editInstructions} onChangeText={setEditInstructions} />
                      </VStack>
                      : ''
                    }

                    <Button onPress={() => { submitAction() }} disabled={!selectedAction || loadingResult} style={styles.button}><Text>Submit</Text></Button>
                    {loadingResult ? <Spinner size={"lg"}/>:''}
                    {results ? 
                      <SunEditor height={'500'} disable={loading} autoFocus={false} setContents={results} />
                    : ''}
                  </Box>
                </>
              

            </VStack>
          </VStack>
        </ScrollView>


      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles,
    {
      stepBox: {
        backgroundColor: colors.BK_COLOR_VVLT,
      }
    })
);
