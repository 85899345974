
import { View, Text, Pressable, FlatList, Spacer, Center, VStack, HStack, Button, Box, Select, Input, ScrollView } from "native-base";
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { StyleSheet } from 'react-native';
import { PageService } from '../../services/Page';
import { SettingsService, settingsService } from "../../services/Settings";
import { colors } from "../../styles/theme.style";
import { Layout } from "../components/Layout";
import { AuthContext, AuthState } from "../context/AuthContext";



let loaded = false;

export const SetupScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const fbUserID = user?.userID;

  const addNewPage = { name: 'Add new page', last: true };
  const [entityType, setEntityType] = useState('business');
  const [entityName, setEntityName] = useState('');
  const [websiteAddress, setWebsiteAddress] = useState('');
  const [hoursOfOps, setHoursOfOps] = useState('');
  const [locationAddresses, setLocationAddresses] = useState('');
  const [phoneNumbers, setPhoneNumbers] = useState('');
  const [emails, setEmails] = useState('');
  const [generalInfo, setGeneralInfo] = useState('');
  const [specialItems, setSpecialItems] = useState('');

  const load = () => {
    if (loaded || !fbUserID) { // prevent loop, prevent undefined call
      return;
    };

    new SettingsService().getSettings(user._id).then((res) => {

      console.log(res);
      setEntityType(res.entityType)
      setEntityName(res.entityName)
      setWebsiteAddress(res.websiteAddress)
      setHoursOfOps(res.hoursOfOps)
      setLocationAddresses(res.locationAddresses)
      setPhoneNumbers(res.phoneNumbers)
      setEmails(res.emails)
      setGeneralInfo(res.generalInfo)
      setSpecialItems(res.specialItems)
      
      loaded = true;
    });
  }

  const cap = (v: string) => {
    return `${v.charAt(0).toUpperCase()}${v.slice(1)}`;
  }

  const save = () => {
    console.log(authState);
    if (!user) {
      throw('user _id is undefined')
    }

    settingsService.updateSettings(user._id, {
      entityType,
      entityName,
      websiteAddress,
      hoursOfOps,
      locationAddresses,
      phoneNumbers,
      emails,
      generalInfo,
      specialItems,
      
    });
  }

  useEffect(() => {
    load();
  });

  return (
    <Layout>
      <View style={styles.container}>
        <VStack>
          <HStack>
            <VStack>
              <Text style={styles.h4}>Information and settings</Text>
              <Text style={styles.p}>General Info</Text>
            </VStack>
            <Spacer />

            <Box>
              <HStack>
                <Button size={'md'} variant="outline" style={styles.button}>CANCEL</Button>
                <Button size={'md'} style={styles.button} onPress={() => {save()}}>SAVE</Button>
              </HStack>
            </Box>
          </HStack>

          <Text style={styles.h4}>____________________________</Text>
          <Text style={styles.p}>Use any format you wish on our inputs. Our AI understands plain english. Leave fields empty if they do not apply</Text>
        </VStack>
        <ScrollView flex="1" padding={10}>

          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>Organization Category - select best fit</Text>
              <Box maxW="300">
                <Select style={styles.inputGeneral} selectedValue={entityType} minWidth="200" accessibilityLabel="Choose Type of Org"
                  placeholder="Choose Best Fit" color={'white'} mt={1} onValueChange={itemValue => setEntityType(itemValue)}>
                  <Select.Item label="Business" value="business" />
                  <Select.Item label="Non Profit Group" value="npo" />
                  <Select.Item label="Blog" value="blog" />
                  <Select.Item label="Vlog" value="vlog" />
                </Select>
              </Box>
            </VStack>
          </HStack>
          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>{cap(entityType)} name</Text>
              
              <Input style={styles.inputGeneral} w="100%" value={entityName} onChangeText={setEntityName}/>
              
            </VStack>
          </HStack>

          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>Website address</Text>
              <Input style={styles.inputGeneral} w="100%" value={websiteAddress} onChangeText={setWebsiteAddress}/>
            </VStack>
          </HStack>

          {entityType === 'business' || entityType === 'npo' &&
            <HStack style={styles.inputHStack}>
              <VStack style={styles.labelStack}>
                <Text style={styles.labelText}>Hours of operation</Text>
                <Input style={styles.inputGeneral} w="100%" value={hoursOfOps} onChangeText={setHoursOfOps}/>
              </VStack>
            </HStack>
          }
          {entityType === 'business' || entityType === 'npo' &&
            <HStack style={styles.inputHStack}>
              <VStack style={styles.labelStack}>
                <Text style={styles.labelText}>Physical address or a list of locations with addresses</Text>
                <Input style={styles.inputGeneral} w="100%" value={locationAddresses} onChangeText={setLocationAddresses}/>
              </VStack>
            </HStack>
          }
          {entityType === 'business' || entityType === 'npo' &&
          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>Phone Numbers with labels. For example: Main 888-555-3333</Text>
              <Input style={styles.inputGeneral} w="100%" value={phoneNumbers} onChangeText={setPhoneNumbers}/>
            </VStack>
          </HStack>
          }
          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>Email addresses optionally add a label for each</Text>
              <Input style={styles.inputGeneral} w="100%" value={emails} onChangeText={setEmails}/>
            </VStack>
          </HStack>
          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>General information about your business, entity, goals or group. Please describe what your company does well because the chatbot will use this information to reply to questions. Be detailed as possible</Text>
              <Input style={styles.inputGeneral} w="100%" value={generalInfo} onChangeText={setGeneralInfo}/>
            </VStack>
          </HStack>

          <HStack style={styles.inputHStack}>
            <VStack style={styles.labelStack}>
              <Text style={styles.labelText}>Please list any current specials your business has or any special announcements. Also feel free to place important topics etc here.</Text>
              <Input style={styles.inputGeneral} w="100%" value={specialItems} onChangeText={setSpecialItems}/>
            </VStack>
          </HStack>
        </ScrollView>
      </View>
    </Layout>
  );
};

const styles = StyleSheet.create({
  labelStack: {
    width: '40%'
  },
  inputGeneral: {
    marginLeft: 0,
    color: colors.TEXT_WHITE
  },
  inputStack: {
    width: '60%'
  },
  inputHStack: {
    marginBottom: 30,
    paddingTop: 10
  },
  container: {
    flex: 1,
    backgroundColor: colors.BK_COLOR_DK,
    justifyContent: 'center',
  },
  button: {
    margin: 10
  },
  cards: {
    padding: 10,
  },
  h4: {
    fontWeight: '600',
    fontSize: 20,
    lineHeight: 28,
    color: colors.TEXT_WHITE,
    margin: 10,
  },
  labelText: {
    fontWeight: '300',
    fontSize: 14,
    lineHeight: 20,
    color: colors.TEXT_WHITE
  },
  p: {
    fontWeight: '300',
    fontSize: 14,
    lineHeight: 20,
    color: colors.TEXT_WHITE,
    margin: 10,
  }
});
