import axios from '../../configurations/request';
import { configService } from '../../services/Config';
import { StyleSheet } from 'react-native';
import { FunctionComponent, useContext, useState } from 'react';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, FormControl, Image, Input, Link, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { userService } from '../../services/User';
import { AuthContext } from '../context/AuthContext';
import { stripeService } from '../../services/Stripe';




const postUserToBackend = async (userID: string, creds: string, navigation: any) => {
  console.log(userID, creds)
  const baseUrl = configService.apiRoot;
  await axios({
    method: "PUT",
    url: `${baseUrl}users/${userID}`,
    data: {
      access_token: creds,
    }
  });

  navigation.navigate('Pages', { userID })
};



/* FB login flow...
 * A. FB login status if connected -> SKIP TO C
 * B. FB login
 * C. Send userID & accessToken to fb login endpoint
 * D. Receive token
*/

export const RegisterScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const context = useContext(AuthContext);
  const [emailVal, setEmailVal] = useState('');
  const [passwordVal, setPasswordVal] = useState('');
  const [working, setWorking] = useState(false);

  const emailRegister = async (email: string, password: string, n?: any) => {
    try {
      setWorking(true);
      const {jwt} = await userService.emailRegister(email, password);
      await context.setTokenTime(Date.now());
      //const {url} = await stripeService.createCheckoutSession(jwt);
      //window.location = url;
      navigation.navigate('BlogAi');
      
      setWorking(false);
    } catch(e) {
      setWorking(false);
      if (e?.response?.data?.reason) {
        toast.show({description: e?.response?.data?.reason})
      } else {
        toast.show({description: 'Unknown error occurred'})
      }
      setWorking(false);
    }

  }

  const fbLogin = async () => {
    window.FB.getLoginStatus(async (response) => {
      if (response.status === 'connected') {
        console.log('connected');
        const { userID, accessToken } = response.authResponse;
        await userService.fbLogin(userID, accessToken);
        context.setTokenTime(Date.now());
      } else {
        window.FB.login(async (response) => {
          if (response.status === 'connected') {
            const { userID, accessToken } = response.authResponse;
            await userService.fbLogin(userID, accessToken);
            context.setTokenTime(Date.now());
          }
        }, { config_id: '1393053344769263', auth_type: '' });
      }
    });
  }

  return (
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={200}>


          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Text fontSize='xl' style={styles.heading}>
            Register
          </Text>
          <Text fontSize='md' style={styles.subheading}>
            30 Day Free Trial, No Credit Card Required.
          </Text>

          <Text w="full" p="16px" style={styles.subText}>Create an account, it's free and super quick!</Text>

        </Square>

        <Square w={360}>

          <FormControl isRequired>
            <Stack mx="4">
              <FormControl.Label><Text style={styles.formLabel}>Email</Text></FormControl.Label>
              <Input type="text" w="full"
              color={colors.TEXT_WHITE}
              value={emailVal} onChangeText={setEmailVal} />
              <FormControl.HelperText>
                Create account using your email
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                Please use a valid email
              </FormControl.ErrorMessage>

              <FormControl.Label><Text style={styles.formLabel}>Password</Text></FormControl.Label>
              <Input type="password" onChangeText={setPasswordVal} color={colors.TEXT_WHITE} />
              <FormControl.HelperText>
                Must be at least 6 characters.
              </FormControl.HelperText>
              <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
                At least 6 characters are required.
              </FormControl.ErrorMessage>
            </Stack>
          </FormControl>
          <Text w="full" padding={'16px'} color={colors.TEXT_WHITE}>By signing up, you agree to our terms of service</Text>
          <Box w="full" p="16px">
            <Button w="full" onPress={() => emailRegister(emailVal, passwordVal, navigation)}>{working? 'Creating Account':'Sign Up'}</Button>
          </Box>
        </Square>
        <Square w={360}>
          <Box w="full" p="16px">
            {/*<Button
              leftIcon={<MaterialIcon name="facebook" size="large" color="#fff" />}
              backgroundColor={colors.FB_BLUE}
              w="full" onPress={() => fbLogin(navigation)}>
              Login With Facebook
          </Button>*/}
          </Box>
          <Text color={colors.TEXT_WHITE}>
            Already have an account? &nbsp;
            <Link color='primary.600' href='/signin'>
              Sign in
            </Link>
          </Text>
        </Square>
      </Square>
    </Center>
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 10,
  },
  subheading: {
    color: colors.TEXT_WHITE,
    fontWeight: '400',
    padding: 0,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});