import axios from '../../configurations/request';
import { configService } from '../../services/Config';
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, View, Pressable } from 'react-native';
import { FunctionComponent, useContext, useState } from 'react';
import { Layout } from '../components/Layout';
import { colors } from '../../styles/theme.style';
import { AspectRatio, Box, Button, Center, Container, FormControl, Icon, Image, Input, Link, Spacer, Square, Stack, Text, useToast, WarningOutlineIcon } from 'native-base';
import { UserService, userService } from '../../services/User';
import { MaterialIcon } from '../components/Icon';
import { AuthContext } from '../context/AuthContext';
import { stripeService } from '../../services/Stripe';
import { StripeProvider } from '@stripe/stripe-react-native';




export const PurchaseScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const startCheckout = async () => {
    const res = await stripeService.createCheckoutSession();
    
    const {url} = res;
    window.location = url;
  }

  return (
    
    <Center style={styles.container}>
      <Square w={408} h={700} style={styles.innerBox}>

        <Square w={360} h={170}>
          <AspectRatio ratio={{
            base: 1520 / 863,
          }} width={{
            base: 129
          }}>
            <Image resizeMode="cover" source={{
              uri: "https://static.aicheetah.io/resources/logo-trans-trimmed.png"
            }} alt="LOGO" />
          </AspectRatio>
          <Pressable onPress={() => {startCheckout()}} style={styles.heading}>
            Buy me!
          </Pressable>
        </Square>

        <Square w={360}>

          
        </Square>
        <Square w={360}>
          <Box w="full" p="16px">
            
          </Box>
          
        </Square>
      </Square>
    </Center>
    
  );
}
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',

    flex: 1,
    backgroundColor: '#f3ba9a'
  },
  innerBox: {
    backgroundColor: colors.BK_THEME_COLOR,
    borderRadius: 16,
    padding: '24px',
  },
  heading: {
    color: colors.TEXT_WHITE,
    fontWeight: '600',
    padding: 20,
  },
  subText: {
    color: colors.TEXT_GREY,
  },
  button: {
    backgroundColor: '#3578e5',
    padding: 10,
    margin: 10,
  },
  buttonText: {
    color: colors.TEXT_WHITE,
  },
  formLabel: {
    color: colors.TEXT_WHITE,
    fontWeight: '600'
  }
});