
import { View, Text, Pressable, FlatList, Spacer, Center, VStack, Button, useToast, Input, FormControl, Box, Checkbox, Radio, TextArea, ScrollView } from "native-base";
import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { StyleSheet, TouchableHighlight } from 'react-native';
import { PageService } from '../../services/Page';
import { userService } from "../../services/User";
import { colors, defaultStyles } from "../../styles/theme.style";
import { MaterialIcon } from "../components/Icon";
import { Layout } from "../components/Layout";
import { MediaCard } from "../components/MediaCard";
import { AuthContext, AuthState } from "../context/AuthContext";
import { cheetahService } from "../../services/Chetah";
import { getResponse } from "../../services/WaitingResponses";


export const BlogHelperScreen: FunctionComponent<any> = ({ route, navigation }) => {
  const toast = useToast();
  const stackWidth = '268px';
  const stackHeight = '340px';
  const context: any = useContext(AuthContext);
  const authState: AuthState = context.authState;
  const { user } = authState;
  const [generateIdeasInput, setGenerateIdeasInput] = useState('');
  const [outlineTitle, setOutlineTitle] = useState('');
  const [ideaList, setIdeaList] = useState([]);
  const [working, setWorking] = useState(false);
  const [outline, setOutline] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [blog, setBlog] = useState('');
  const [verbosity, setVerbosity] = useState('medium'); //lowest possible, medium, highest possible

  const handleErrors = (error: Error) => {
    console.log(error);
    alert('there has been an error, please retry');
    setWorking(false);
  }

  const handleUserError = (info: string) => {
    alert(info);
  }

  const generateIdeas = async () => {
    const settingsId = user?._id || '';
    if (!generateIdeasInput) {
      return handleUserError('Please input a blog subject/idea');
    }
    setWorking(true);
    const ideas: string | void = await cheetahService.generateBlogIdeas(settingsId, generateIdeasInput)
      .catch(handleErrors);
    if (!ideas) { return }
    setIdeaList(ideas.split("\n"));
    setWorking(false);
  }

  const selectOutlineTitle = (title) => {
    setOutlineTitle(title.replace(/^\d\./, ''))
  }

  const generateOutline = async () => {
    if (!generateIdeasInput) {
      return handleUserError('Please input a blog subject/idea');
    }
    if (!outlineTitle) {
      return handleUserError('Please input a blog title');
    }
    setWorking(true);
    const settingsId = user?._id || '';
    const _outline: string | void = await cheetahService.generateOutline(settingsId, outlineTitle, generateIdeasInput, additionalInfo)
      .catch(handleErrors);
    if (!_outline) { return }
    setOutline(_outline)
    setWorking(false);
  }

  const generateBlogFromOutline = async () => {
    if (!generateIdeasInput) {
      return handleUserError('Please input a blog subject/idea');
    }
    if (!outlineTitle) {
      return handleUserError('Please input a blog title');
    }
    if (!outline) {
      return handleUserError('Please input a blog outline');
    }
    
    setWorking(true);
    const settingsId = user?._id || '';
    const blogId: string | void = await cheetahService.generateBlogFromOutline(settingsId, generateIdeasInput, verbosity, outlineTitle, outline, additionalInfo)
      .catch(handleErrors);
    
      navigation.navigate('ArticleTools', {blogId})
    setWorking(false);
  }

  const load = () => {
    // get settings
  }



  useEffect(() => {
    load();
    console.log('use effect hook');
  });

  const renderIdea = ({ item: idea }: { item: string }) => (

    <TouchableHighlight onPress={() => console.log(idea)}>
      <Center flex={1} marginLeft={5} marginRight={5}>
        <Box flex={1} w="full">

          <Radio value={idea}><Text style={styles.p}>{idea}</Text></Radio>
        </Box>
      </Center>
    </TouchableHighlight>
  );

  return (
    <Layout>
      <View style={styles.container}>
        <VStack mx='4'>
          <Text style={styles.h4}>Blog helper - AI Wizard to generate blog ideas, outlines and blog posts</Text>
        </VStack>
        <ScrollView flex="1" mx="10" padding={10}>
          <VStack>
            <VStack p={10} style={styles.stepBox}>
              <Text style={styles.p}>Enter a topic</Text>
              <FormControl.Label>
                <Text style={styles.formLabel}>Blog topic / category:</Text>
              </FormControl.Label>
              <Input type="text" placeholder="e.g., Gardening" style={styles.inputGeneral} w="100%" value={generateIdeasInput} onChangeText={setGenerateIdeasInput} />
            </VStack>
            <VStack p={10} marginTop={5} style={styles.stepBox}>
              <Text style={styles.p}>Enter an article title OR click "Give me some ideas"</Text>
              <Button disabled={working} style={styles.button} width={"1/3"} onPress={generateIdeas}><Text>Give Me Some Ideas {working ? '...WORKING' : ''}</Text></Button>

              <View flex={1}>
                <Text style={styles.p}>{ideaList.length ? 'Select an idea' : ''}</Text>
                <Radio.Group onChange={selectOutlineTitle}>
                  <FlatList flex={1} data={ideaList} renderItem={renderIdea} />
                </Radio.Group>

              </View>
              <FormControl.Label>
                <Text style={styles.formLabel}>Blog Title:</Text>
              </FormControl.Label>
              <Input type="text" style={styles.inputGeneral} w="100%" value={outlineTitle} onChangeText={setOutlineTitle} />
            </VStack>

            <VStack p={10} marginTop={5} style={styles.stepBox}>
              <Text style={styles.p}>Optional details or information. You can add any custom information to your article here. For example, if your article should cover a real life event, please add details about the event. Also mention any styles or special formatting you would like here:</Text>
              <TextArea style={styles.inputGeneral} h={200} placeholder="OPTIONAL - Additional info goes here" w="100%" value={additionalInfo} onChangeText={setAdditionalInfo} />
            </VStack>

            <VStack p={10} marginTop={5} style={styles.stepBox}>
              <Text style={styles.p}>Enter an outline OR click "Generate An Outline". You may edit the generated outline any way you see fit.</Text>
              <Button disabled={working} style={styles.button} width={"1/3"} onPress={generateOutline}><Text>Generate An Outline {working ? '...WORKING' : ''}</Text></Button>
              <TextArea style={styles.inputGeneral} h={200} placeholder="Outline goes here" w="100%" value={outline} onChangeText={setOutline} />
            </VStack>

            <VStack p={10} marginTop={5} style={styles.stepBox}>
              <Text style={styles.p}>Click to generate a blog post using the topic, title, & outline from above. You will be redirected to the editing tool.</Text>
              <Button style={styles.button} width={"1/3"} onPress={generateBlogFromOutline}><Text>Generate Blog Post {working ? '...WORKING' : ''}</Text></Button>
            </VStack>
          </VStack>
        </ScrollView>


      </View>
    </Layout>
  );
};

const borderRadius = 20;

const styles = StyleSheet.create(
  //@ts-ignore
  Object.assign(defaultStyles,
    {
      iframe: {
        border: 'none',
        backgroundColor: 'white'
      },
      stepBox: {
        backgroundColor: colors.BK_COLOR_VVLT
      }
    })
);
